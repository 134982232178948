import styled, { css } from 'styled-components'
import { mediaMin } from 'common/layout'
import { colors } from 'common/styles'

export const StyledFooter = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  margin: 2px 0px 50px;
  font-family: sans-serif;
  color: ${colors.white};
`
export const StyledFooterLink = styled.a`
  text-decoration: none;
  color: ${colors.white};
  font-size: 14px;
  font-family: Roboto, sans-serif;
  margin: 2px 15px;
`
