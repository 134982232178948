import styled from 'styled-components'
import logo from 'common/assets/logo_de_DE.svg'

export const StyledLogo = styled.a`
  position: absolute;
  top: 32px;
  left: 36px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
`
export const StyledSvg = styled(logo)`
  width: 118px;
  cursor: pointer;
`
