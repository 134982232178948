import React from 'react'
import karli from '/common/assets/karli-body-2.png'
import { KarliContainer, KarliImage, CTA, CTAText, More, Arrow } from './styles'

const Karli = () => {
  return (
    <KarliContainer>
      <KarliImage src={karli} />
      <CTA>
        <CTAText>
          Entdecke das <br />
          <More>"mehr" </More>
          bei RAJA.
        </CTAText>
        <Arrow rotation={0} />
      </CTA>
    </KarliContainer>
  )
}

export default Karli
