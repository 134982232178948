export const openIOSARQuickLook = path => {
  const anchor = document.createElement('a')
  anchor.setAttribute('rel', 'ar')
  anchor.setAttribute('href', path)
  anchor.appendChild(document.createElement('img'))
  anchor.click()
}

export const openAndroidQuickLook = (path, sound) => {
  const anchor = document.createElement('a')
  const arPath = `intent://arvr.google.com/scene-viewer/1.0?file=${path}&mode=ar_only${
    sound && `&sound=${sound}`
  }#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=https://play.google.com/store/apps/details?id=com.google.ar.core;end;`
  anchor.setAttribute('href', arPath)
  anchor.click()
}
