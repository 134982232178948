import React from 'react'
import { StyledLogo, StyledSvg } from './styles'

const Logo = () => {
  return (
    <StyledLogo href="https://www.rajapack.de/" target="_blank" rel="noopener noreferrer">
      <StyledSvg />
    </StyledLogo>
  )
}

export default Logo
