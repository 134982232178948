import React from 'react'
import { KarliContainer, Karli, CTA, CTAText, More, Arrow, ScanCTA } from './styles'

const KarliQR = () => {
  return (
    <KarliContainer>
      <Karli>
        <CTA>
          <CTAText>
            Entdecke das <br />
            <More>"mehr" </More>
            bei RAJA.
          </CTAText>
          <Arrow rotation={0} />
        </CTA>
        <ScanCTA>
          <Arrow rotation={150} />
          <CTAText>Scan mich!</CTAText>
        </ScanCTA>
      </Karli>
    </KarliContainer>
  )
}

export default KarliQR
