import { css } from "styled-components"

export const breakPoints = {
  extraSmall: { min: 0, max: 279 },
  small: { min: 280, max: 767 },
  medium: { min: 768, max: 1365 },
  large: { min: 1366, max: 99999 },
}
export const mediaMin = (breakpoint, style) => css`
  @media only screen and (min-width: ${breakPoints[breakpoint].min}px) {
    ${style}
  }
`