import React from 'react'
import { StyledFooter, StyledFooterLink } from './styles'

const Footer = () => {
  return (
    <StyledFooter>
      <StyledFooterLink
        href={'https://www.rajapack.de/impressum_cms_000006.html'}
        target="_blank"
        rel="noopener noreferrer"
      >
        Impressum
      </StyledFooterLink>
      <span>|</span>
      <StyledFooterLink
        href={'https://www.rajapack.de/datenschutz_cms_000005.html'}
        target="_blank"
        rel="noopener noreferrer"
      >
        Datenschutz
      </StyledFooterLink>
    </StyledFooter>
  )
}

export default Footer
