import styled, { css } from 'styled-components'
import { colors } from 'common/styles'
import { mediaMin } from 'common/layout'
import soundwave from 'common/assets/audiowave.svg'
import instagram from 'common/assets/instagram.svg'
import img from '/common/assets/karli-bg.png'

export const StyledMain = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 40vh;
  background-image: linear-gradient(0deg, ${colors.darkGrey} 75%, rgba(255, 255, 255, 0) 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${mediaMin(
    'medium',
    css`
      margin-top: 50vh;
    `
  )}
`

export const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url(${img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

export const DesktopMain = styled.div`
  position: absolute;
  background-image: url(${img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`

export const DesktopInfo = styled.p`
  margin-top: -6%;
  font-weight: bold;
  color: ${colors.white};
  text-align: center;
  font-size: 14px;
  width: 80%;
  max-width: 500px;
`

export const StyledArButton = styled.button`
  cursor: pointer;
  background: ${colors.blue};
  color: ${colors.white};
  padding: 19px;
  margin: 20vh 0px 20px;
  border: none;
  border-radius: 54px;
  font-family: Roboto, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledHeadphoneInfo = styled.div`
  color: ${colors.white};
  font-size: 14px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`

export const SurfaceInfo = styled.div`
  color: ${colors.white};
  text-align: center;
  font-size: 14px;
  width: 80%;
  max-width: 360px;
  margin-top: 16px;
`

export const StyledSoundwave = styled(soundwave)`
  width: 21px;
  height: 14px;
  margin-right: 8px;
`
export const InstagramLogo = styled(instagram)`
  width: 16px;
  height: 16px;
  margin-left: 4px;
`
export const InstagramInfo = styled.div`
  color: ${colors.white};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  width: 80%;
  max-width: 360px;
  margin-top: 22px;
  margin-bottom: 1%;
`
export const DesktopInstagramInfo = styled.div`
  color: ${colors.white};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  width: 80%;
  max-width: 360px;
  margin-bottom: 30px;
`

export const InstagramLink = styled.a`
  color: ${colors.white};
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
`

export const StyledDeviceInfo = styled.div`
  font-size: 14px;
  text-align: center;
  margin-top: 50px;
  width: 80%;
  max-width: 360px;
`

export const DesktopDeviceInfo = styled.div`
  font-size: 14px;
  text-align: center;
  color: ${colors.white};
  margin-bottom: 20px;
  width: 80%;
  max-width: 500px;
`

export const StyledTerms = styled.a`
  text-decoration: none;
  margin-top: 10px;
  color: ${colors.grey};
  font-size: 14px;
  font-family: Roboto, sans-serif;
  font-weight: bold;
`
export const StyledDesktopTerms = styled.a`
  text-decoration: none;
  margin-top: 10px;
  color: ${colors.white};
  font-size: 14px;
  font-family: Roboto, sans-serif;
  font-weight: bold;
`
