const deployments = {
  'raja.k8s.dmdr.io': 'PRODUCTION',
  'develop.raja.k8s.dmdr.io': 'DEVELOP',
  'raja.kube.dmdr.io': 'PRODUCTION',
  'develop.raja.kube.dmdr.io': 'DEVELOP',
  'karli-rajapack.de': 'PRODUCTION',
}
const isProd = deployments[window.location.hostname] === 'PRODUCTION'
const assetVersion = isProd ? 'v8' : 'dev'

export const assetUrl = `https://d1rbm2gssqqs2v.cloudfront.net/assets/${assetVersion}/`
console.log({assetUrl})