import React from 'react'
import { isIos, isAndroid, isMobile } from 'mobile-device-detect'
import { openIOSARQuickLook, openAndroidQuickLook } from 'utils/ar'
import { GlobalStyles } from 'common/styles'
import { assetUrl } from 'utils/deployments'
import Logo from '../Logo'
import Karli from '../Karli'
import KarliQR from '../KarliQR'
import Footer from '../Footer'
import DesktopFooter from '../DesktopFooter'
import {
  StyledMain,
  BackgroundImage,
  StyledArButton,
  StyledHeadphoneInfo,
  SurfaceInfo,
  StyledSoundwave,
  InstagramLogo,
  InstagramInfo,
  InstagramLink,
  StyledDeviceInfo,
  StyledTerms,
  DesktopMain,
  DesktopInfo,
  StyledDesktopTerms,
  DesktopDeviceInfo,
  DesktopInstagramInfo,
} from './styles'

const App = () => {
  return (
    <>
      <GlobalStyles />
      {!isMobile ? (
        <>
          <DesktopMain>
            <KarliQR />
            <DesktopInfo>
              Sieht so aus, als surfst du gerade auf dem Desktop. Kein Ding, scanne einfach den QR-Code mit Handy oder
              Tablet und lerne Karli in AR kennen.
            </DesktopInfo>
            <DesktopInstagramInfo>
              <p>
                Auch bei{' '}
                <InstagramLink
                  href="https://www.instagram.com/ar/1029513711218330/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram
                </InstagramLink>
              </p>
              <InstagramLogo />
            </DesktopInstagramInfo>
            <DesktopDeviceInfo>
              <b>Info: </b>Augmented Reality funktioniert nur auf neueren Smartphones und Tablets. Wir erfassen nur
              deinen Besuch auf dieser Website. Wir haben zu keinem Zeitpunkt Zugriff auf deine Kamera oder dein Mikro.
              Mehr Details hierzu findest du in unserem Datenschutz.
            </DesktopDeviceInfo>
            <StyledDesktopTerms
              href={'https://www.rajapack.de/verpackungsnews/download/ar-karli-teilnahmebedingungen/'}
              target="_blank"
              rel="noopener noreferrer"
            >
              Zum Gewinnspiel
            </StyledDesktopTerms>
            <DesktopFooter />
          </DesktopMain>
          <Logo />
        </>
      ) : (
        <>
          <BackgroundImage />
          <Karli />
          <StyledMain>
            <StyledArButton
              onClick={() => {
                if (isIos) {
                  openIOSARQuickLook(assetUrl + 'karli.usdz')
                } else if (isAndroid) {
                  openAndroidQuickLook(assetUrl + 'karli.glb', assetUrl + 'karli.m4a')
                } else {
                  openIOSARQuickLook(assetUrl + 'karli.usdz')
                }
              }}
            >
              Lerne Karli in AR kennen
            </StyledArButton>
            <StyledHeadphoneInfo>
              <StyledSoundwave />
              Am besten mit Ton an oder Kopfhörern.
            </StyledHeadphoneInfo>
            <SurfaceInfo>
              Wähle eine passende Oberfläche – wie einen Tisch mit etwas darauf – um Karli zu platzieren.
            </SurfaceInfo>
            <InstagramInfo>
              <p>
                Auch bei{' '}
                <InstagramLink
                  href="https://www.instagram.com/ar/1029513711218330/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram
                </InstagramLink>
              </p>
              <InstagramLogo />
            </InstagramInfo>
            <StyledDeviceInfo>
              <b>Info: </b>Augmented Reality funktioniert nur auf neueren Smartphones und Tablets. Wir erfassen nur
              deinen Besuch auf dieser Website. Wir haben zu keinem Zeitpunkt Zugriff auf deine Kamera oder dein Mikro.
              Mehr Details hierzu findest du in unserem Datenschutz.
            </StyledDeviceInfo>
            <StyledTerms
              href={'https://www.rajapack.de/verpackungsnews/download/ar-karli-teilnahmebedingungen/'}
              target="_blank"
              rel="noopener noreferrer"
            >
              Zum Gewinnspiel
            </StyledTerms>
            <Footer />
          </StyledMain>
          <Logo />
        </>
      )}
    </>
  )
}
export default App
